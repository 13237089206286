import Model from '@/core/models/Model';
import RequiredDocument from '@/models/RequiredDocument';
import type { RequiredDocument as IRequiredDocument } from '@/types/RequiredDocument';

class RequiredDocumentService {
  get(search: string = '') {
    return useAsyncData<IRequiredDocument[]>(
      'required-documents',
      async () => {
        const document = new RequiredDocument();
        if (search) {
          document.where('search', search);
        }

        return Model.processResponse(await document.get()) as IRequiredDocument[];
      },
      { server: false }
    );
  }
  async post(form: any) {
    const { data, error, execute } = useSubmit();
    await execute('required-documents', {
      method: 'POST',
      body: form,
    });

    return {
      data,
      error,
    };
  }
}

const service = new RequiredDocumentService();
export default service;
