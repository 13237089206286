<script setup lang="ts">
import { AppCustomDatePicker, AppFileUpload, AppFormCard, AppTypography } from '@/core/components';
import { DocumentType } from '@/core/enums/documentType';
import { useAuthStore } from '@/modules/auth/store/auth';
import type { User as IUser } from '@/modules/auth/types/Auth';
import requiredDocumentService from '@/services/RequiredDocumentService';
import type { RequiredDocument as IRequiredDocument } from '@/types/RequiredDocument';
import dayjs from 'dayjs';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    issueDateLabel?: string;
    documentType?: string;
    hideMedia?: boolean;
    noBorder?: boolean;
    readonly?: boolean;
  }>(),
  {
    title: '',
    issueDateLabel: 'Enter Issue Date',
    documentType: DocumentType.ID_VERIFICATION,
    hideMedia: false,
    noBorder: false,
    readonly: false,
  }
);

const emits = defineEmits<{
  (e: 'pending', status: boolean): void;
}>();

const { VALIDATIONS } = useValidation();
const {
  v$,
  form,
  handleSubmit: onSubmit,
  getErrors,
} = useForm<IRequiredDocument>({
  form: {
    document_type: props.documentType,
    issue_date: '',
    media: null,
    attachment: null,
  },
  rules: {
    issue_date: {
      required: VALIDATIONS.required('Issue date'),
      date: VALIDATIONS.dateRequired('Issue date'),
      minDate: VALIDATIONS.minDateIfRequired(
        'Issue date',
        dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
        props.documentType === DocumentType.POLICE_CHECKS
      ),
    },
  },
});

const { user, setUser } = useAuthStore();
const success = ref(false);
const requiredDocumentItems = ref<IRequiredDocument[]>([]);
const { data, error: serverError, execute: execSubmit, pending } = useSubmit();

const currentDocument = computed((): IRequiredDocument => {
  return requiredDocumentItems.value
    ? requiredDocumentItems.value.find((item) => item.document_type === props.documentType)
    : null;
});

const currentDocumentMediaId = computed((): IRequiredDocument => {
  return currentDocument.value && currentDocument.value?.media?.length ? currentDocument.value.media[0].id : null;
});

const errors = computed(() => {
  return getErrors(serverError.value?.data?.errors);
});

const isDisabled = computed((): boolean => {
  if (props.documentType === DocumentType.POLICE_CHECKS) {
    return !form.value.issue_date || form.value.media == '' || dayjs().diff(dayjs(form.value.issue_date), 'day') > 90;
  }

  return !form.value.issue_date;
});

const hasChanges = computed((): boolean => {
  return currentDocument.value
    ? !dayjs(form.value.issue_date).isSame(dayjs(currentDocument.value.issue_date), 'day') ||
        (!props.hideMedia
          ? JSON.stringify(currentDocument.value.media.map((a) => a.id).sort()) !==
            JSON.stringify([form.value.media].filter((a) => !!a))
          : false)
    : true;
});

async function initialize(): Promise<void> {
  if (currentDocument.value) {
    const media = Array.isArray(currentDocument.value.media) ? currentDocument.value.media[0] : null;

    form.value.document_type = currentDocument.value.document_type;
    form.value.issue_date = currentDocument.value.issue_date;
    form.value.media = media?.id;
    form.value.attachment = media;
  }

  v$.value.$reset();
}

async function fetchDocuments(): Promise<void> {
  const { data } = await requiredDocumentService.get();
  requiredDocumentItems.value = data.value as IRequiredDocument[];
}

async function handleSubmit(): Promise<void> {
  const payload = JSON.parse(JSON.stringify(form.value)) as IRequiredDocument;
  const media = form.value.media;

  if (payload.issue_date) {
    payload.issue_date = dayjs(payload.issue_date).format('YYYY-MM-DD');
  }

  payload.media = [];
  if (!props.hideMedia) {
    if (media && media !== currentDocumentMediaId.value) {
      payload.media = [media];
    }
  }

  return await onSubmit(async () => {
    emits('pending', true);

    try {
      await execSubmit('required-documents', {
        method: 'POST',
        body: payload,
      });
      emits('pending', false);
      success.value = true;

      setUser(data.value?.data as IUser);
      requiredDocumentItems.value = data.value?.data?.documents;
      initialize();
    } catch (error) {
      emits('pending', false);
      success.value = false;
    }

    return success.value;
  });
}

onMounted(() => {
  nextTick(async () => {
    await fetchDocuments();
    await initialize();
  });
});

defineExpose({
  handleSubmit,
  isDisabled,
  hasChanges,
});
</script>

<template>
  <div>
    <v-form @submit.prevent="handleSubmit">
      <AppFormCard :title="title" :class="{ 'inner-card': !noBorder, 'mt-4': !noBorder }">
        <AppCustomDatePicker
          v-model="form.issue_date"
          class="pa-0 my-4"
          :label="issueDateLabel"
          :readonly="readonly"
          :error-messages="errors.issue_date"
        />

        <div v-if="!hideMedia" class="d-flex flex-column pa-0 mt-4">
          <AppTypography type="subheadline-1" class="my-2">Upload File</AppTypography>
          <AppFileUpload
            :file-types="[
              'image/jpg',
              'image/jpeg',
              'image/png',
              'image/heic',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]"
            v-model="form.media"
            :value="form.attachment"
            :readonly="readonly"
          />
        </div>
      </AppFormCard>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.inner-card {
  border: 1px solid #e0e0e0;
}
</style>
