<script lang="ts" setup>
import { AppTypography, AppTextField } from '@/components/base';
import icUploadIcon from '@/assets/img/icon/ic-upload.svg';
import { find, filter, includes, findIndex } from 'lodash';
import { FILE_STATUS } from '@/modules/media/utils/enums/FileStatus';
import type PresignChunk from '@/types/PresignChunk';
import useMedia from '@/modules/media/composables/useMedia';
import type { Media } from '@/modules/media/types/Media';
import type { MediaValidationError } from '@/modules/media/types/Media';

const props = withDefaults(
  defineProps<{
    value?: Media[];
    multiple?: boolean;
    maxSize?: number;
    fileTypes?: string[];

    placeholder?: string;
    readonly?: boolean;
    disabled?: boolean;
    hideDetails?: boolean | string;
    errorMessages?: string | string[];
  }>(),
  {
    value: () => [],
    multiple: false,
    // @NOTE: In MB
    maxSize: 10,
    fileTypes: () => ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/heic', 'application/pdf'],

    placeholder: '',
    readonly: false,
    disabled: false,
    hideDetails: 'auto',
    errorMessages: () => [],
  }
);

const emits = defineEmits<{
  (e: 'loading', value: boolean): void;
  (e: 'removed'): void;
  (e: 'update:model-value', value: number[]): void;
}>();

const fileInput = ref();
const model = defineModel<Media[] | undefined>({ required: true });
const medias = ref<Media[]>([]);
const hasNewFile = ref(false);
const { uploading, uploadMedia, validateMedia } = useMedia();
const { showSnackbar } = useSnackbar();

const assistiveText = computed((): string => {
  return `Max. ${props.maxSize}MB - ${readableTypes.value}`;
});

const readableTypes = computed(() => {
  const types = props.fileTypes.map((type) => type.split('/')[1]);
  types[types.indexOf('vnd.openxmlformats-officedocument.wordprocessingml.document')] = 'docx';
  types[types.indexOf('msword')] = 'doc';
  return types.join(', ').toUpperCase();
});

const inputPlaceholder = computed((): boolean => {
  return hasUpload.value ? '' : props.placeholder;
});

const hasUpload = computed((): boolean => {
  return !!medias.value.length;
});

watch(
  () => model.value,
  () => {
    if (!hasNewFile.value && model.value?.length) {
      initialize(model.value);
    }
  }
);

watch(
  () => props.value,
  () => {
    initialize();
  },
  { deep: true }
);

watch(
  medias,
  () => {
    const value = medias.value.length ? medias.value.map((a) => a.id) : [];
    emits('update:model-value', props.multiple ? value : value.length ? value[0] : '');
  },
  { deep: true }
);

const initialize = (media: Media[] | null = null): void => {
  const newMedia = media || props.value;

  if (newMedia) {
    const newValue = (Array.isArray(newMedia) ? newMedia : [newMedia] || []).filter((a) => a && a.id);
    if (props.multiple) {
      medias.value = [...medias.value, ...newValue];
    } else {
      medias.value = [...newValue];
    }
  }
};

const selectFile = (event): void => {
  // @NOTE: Had to filter by `target` as `v-text-field` doesn't have an event
  // for just clicking input field itself
  if (event.target.nodeName !== 'INPUT' || uploading.value || props.readonly) return;

  fileInput.value.click();
};

const addFiles = async (files: FileList): Promise<void> => {
  hasNewFile.value = true;

  const uploadErrors: MediaValidationError[] = [];
  const addError = (name: string, msg: string): void => {
    uploadErrors.push({ name, msg });
  };
  emits('loading', true);
  for (const file of files) {
    try {
      await validateMedia(file, { maxSize: props.maxSize, fileTypes: props.fileTypes });
      const { media, error } = await uploadMedia(file);

      if (media) {
        if (props.multiple) {
          medias.value.push(media);
        } else {
          medias.value = [media];
        }
      } else if (error.value?.data?.message) {
        addError(file.name, error.value.data.message);
      }
    } catch (e) {
      addError(file.name, e.message);
    }
    emits('loading', false);
  }

  if (uploadErrors.length) {
    const messageHeader = `Failed to select ${uploadErrors.length} file${uploadErrors.length > 1 ? 's' : ''}`;
    showSnackbar({
      text: messageHeader,
      items: uploadErrors.map((a) => `${a.name}: ${a.msg}`),
      state: false,
    });
  }
};

const removeFile = (media: Media) => {
  const index = findIndex(medias.value, { id: media.id });
  if (index >= 0) {
    medias.value.splice(index, 1);

    emits('removed', media);
  }
};

onMounted(() => {
  initialize();
});
</script>
<template>
  <div class="app-file-upload">
    <v-text-field
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      :disabled="disabled || uploading"
      :readonly="true"
      :placeholder="inputPlaceholder"
      flat
      variant="solo-filled"
      class="app-file-upload__input"
      @click:control="selectFile"
    >
      <template #prepend-inner>
        <div class="app-file-upload__border">
          <template v-for="(media, n) in medias">
            <template v-if="multiple">
              <v-chip :key="n" class="app-file-upload__chip">
                {{ media.file_name }}
                <v-icon icon="mdi-close" class="ml-2" @click="removeFile(media)"></v-icon>
              </v-chip>
            </template>
            <AppTypography v-else :key="n" type="body-1">{{ media.file_name }}</AppTypography>
          </template>
        </div>
      </template>
      <template #append-inner>
        <v-icon
          v-if="hasUpload && !multiple && !readonly"
          icon="mdi-close"
          class="app-file-upload__icon mr-2"
          @click="removeFile(medias[0])"
        />
        <v-icon class="app-file-upload__icon">
          <img :src="icUploadIcon" width="24px" height="24px" alt="File Upload icon" />
        </v-icon>
      </template>
    </v-text-field>
    <AppTypography type="caption-2" class="app-file-upload__text ml-2 my-2">{{ assistiveText }}</AppTypography>

    <input
      ref="fileInput"
      type="file"
      :multiple="multiple"
      :disabled="uploading"
      style="display: none"
      :accept="readableTypes"
      @change="addFiles($event.target.files)"
    />
  </div>
</template>
<style lang="scss" scoped>
.app-file-upload {
  :deep(.v-input) {
    .v-field {
      grid-template-columns: max-content minmax(0, 1fr) min-content min-content;
    }
  }

  &__text {
    color: #77797d;
  }

  &__icon {
    opacity: 1 !important;
  }

  &__border {
    .v-chip {
      height: 30px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
