<script setup lang="ts">
import { AppErrorMessages, AppTextField, AppTypography } from '@/core/components';
import type { CustomDate as ICustomDate } from '@/core/types/CustomDate';
import dayjs from 'dayjs';

const props = withDefaults(
  defineProps<{
    label?: string;
    readonly?: boolean;
    errorMessages?: string | string[];

    isBefore?: boolean;
  }>(),
  {
    label: '',
    readonly: false,
    errorMessages: () => [],

    isBefore: true,
  }
);

const dayMaskOption = {
  mask: '##',
  postProcess: (value: number) => (value > 31 ? 31 : value),
};

const monthMaskOption = {
  mask: '##',
  postProcess: (value: number) => (value > 12 ? 12 : value),
};
const yearMaskOption = {
  mask: '####',
  postProcess: (value: number) => (value > 2999 ? 2999 : value),
};

const emits = defineEmits<{
  (e: 'update:modelValue', customDate: Date | string): void;
}>();

const customDate = ref<ICustomDate>({
  day: '',
  month: '',
  year: '',
});

const hasModelUpdate = ref(false);
const model = defineModel<Date | string | undefined>({ required: true });

watch(
  model,
  (value) => {
    if (value && !hasModelUpdate.value) {
      const date = dayjs(value);
      customDate.value = {
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
      } as ICustomDate;
    }
  },
  { once: true }
);

const isValid = computed((): boolean => {
  const currentDate = dayjs();
  const date = dayjs(`${customDate.value.year}-${customDate.value.month}-${customDate.value.day}`);

  return (
    customDate.value.day >= 1 &&
    customDate.value.day <= 31 &&
    customDate.value.month >= 1 &&
    customDate.value.month <= 12 &&
    customDate.value.year >= 1900 &&
    (props.isBefore ? date.isSameOrBefore(currentDate, 'day') : date.isSameOrAfter(currentDate, 'day'))
  );
});

const errors = computed((): string => {
  return (
    (Array.isArray(props.errorMessages) ? props.errorMessages[0] : props.errorMessages) ||
    (hasModelUpdate.value && !isValid.value ? 'Date is invalid' : '')
  );
});

function formatDate(date: ICustomDate): Date {
  hasModelUpdate.value = true;
  if (!isValid.value) return;

  const { day, month, year } = date;
  return new Date(`${year}-${month}-${day}`);
}
</script>
<template>
  <div>
    <AppTypography type="subheadline-1" class="my-2">{{ label }}</AppTypography>
    <v-row no-gutters>
      <v-col>
        <AppTextField
          v-model="customDate.day"
          type="number"
          label="Day"
          class="pr-1 no-spinners"
          hide-details
          v-maska:[dayMaskOption]
          :readonly="readonly"
          :error-messages="errors"
          @update:model-value="emits('update:modelValue', formatDate(customDate))"
        />
      </v-col>
      <v-col>
        <AppTextField
          v-model="customDate.month"
          type="number"
          label="Month"
          class="px-1 no-spinners"
          hide-details
          v-maska:[monthMaskOption]
          :readonly="readonly"
          :error-messages="errors"
          @update:model-value="emits('update:modelValue', formatDate(customDate))"
        />
      </v-col>
      <v-col>
        <AppTextField
          v-model="customDate.year"
          type="number"
          label="Year"
          class="pl-1 no-spinners"
          hide-details
          v-maska:[yearMaskOption]
          :readonly="readonly"
          :error-messages="errors"
          @update:model-value="emits('update:modelValue', formatDate(customDate))"
        />
      </v-col>
    </v-row>

    <AppErrorMessages v-if="errors" :error="errors" display="single" />
  </div>
</template>

<style lang="scss" scoped>
.error-message {
  color: red;
  padding-top: 0.5rem;
}
</style>
