export default function useMedia() {
  const { data, error, pending: uploading, execute } = useSubmit();

  const uploadMedia = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    await execute('media', {
      method: 'POST',
      body: formData,
    });

    return {
      media: data.value?.data,
      error,
    };
  };

  const validateMedia = (
    file: File,
    options?: {
      maxSize?: number;
      fileTypes?: string[];
    } = {}
  ) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error('File is required'));
      }

      if (options.maxSize && toMb(file.size) > options.maxSize) {
        reject(new Error(`File size must be equal or less than ${options.maxSize}MB`));
      }

      if (options.fileTypes && !isValidType(file, options.fileTypes)) {
        const sentence = (arr, conjunction = 'or') => {
          if (Array.isArray(arr)) {
            /**
             * Remove other formats on the error message
             **/
            const tmpSentence = [...arr].filter(
              (a) =>
                ![
                  'msword',
                  'vnd.ms-powerpoint',
                  'vnd.openxmlformats-officedocument.presentationml.presentation',
                  'vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'vnd.ms-excel',
                  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ].includes(a)
            );

            if (tmpSentence.length <= 2) {
              return tmpSentence.join(` ${conjunction} `);
            }

            const last = tmpSentence.pop();
            return tmpSentence.join(', ') + ` ${conjunction} ` + last;
          }

          return arr;
        };

        reject(new Error(`File must be ${sentence(options.fileTypes)}`));
      }

      resolve(File);
    });
  };

  const toMb = (size: number): number => {
    return size ? size / 1024 / 1024 : 0;
  };

  const isValidType = (file: File, validTypes: string[]): boolean => {
    return validTypes.includes(getFileType(file));
  };

  const getFileType = (file: File): string => {
    return file.type || file.mime_type || '';
  };

  return {
    uploading,

    uploadMedia,
    validateMedia,
  };
}
